<template>
  <div class="card">
    <div class="card-body">
      <div class="table-responsive">
        <b-table
          show-empty
          striped
          class="orders-list"
          :items="wc_orders"
          :fields="fields"
          responsive="sm"
          :per-page="perPage"
          :current-page="currentPage"
        >
          <!-- Order ID  -->
          <template v-slot:cell(orderId)="row">
            &#x23;{{ row.item.order_data.orderId }}
          </template>
          <!-- Flight type:  -->
          <template v-slot:cell(flightType)="row">
            {{ row.item.order_data.flightType }}
          </template>
          <!-- Date & Time -->
          <template v-slot:cell(startTime)="row">
            {{ formatDate(row.item.order_data.startTime) }}
            <small class="start-time">{{
              formatTime(row.item.order_data.startTime)
            }}</small>
          </template>
          <!-- cumtomer's name -->
          <template v-slot:cell(customerName)="row">
            {{ row.item.order_data.customerName }}
            <small class="customer-phone"
              ><i class="uil uil-phone font-12 text-success mr-1"></i
              >{{ row.item.order_data.customerPhone }}</small
            >
          </template>
          <!-- Payment Status -->
          <template v-slot:cell(changeStatus)="row">
            <span
              class="badge mr-2"
              :class="{
                'badge-success': row.item.order_data.bookingStatus === 'paid',
                'badge-info': row.item.order_data.bookingStatus === 'complete',
                'badge-danger':
                  row.item.order_data.bookingStatus === 'cancelled',
                'badge-light': row.item.order_data.bookingStatus === 'unpaid',
                'badge-danger-lighten':
                  row.item.order_data.bookingStatus === 'Payment Failed',
              }"
              >{{ row.item.order_data.bookingStatus }}</span
            >
          </template>

          <!-- Payment -->
          <template v-slot:cell(totalPayment)="row">
            <span class="total-payment"
              >₹{{ parseInt(row.item.order_data.totalPayment) }}</span
            >
          </template>
          <template v-slot:cell(actions)>
            <a title="View" class="action-icon view">
              <i class="mdi mdi-eye"></i>
            </a>
          </template>
        </b-table>
      </div>
      <div class="row">
        <!-- # of entries per page selector -->
        <div class="col-12 col-sm-6 text-center text-sm-left mb-2 mb-sm-0">
          <div id="tickets-table_length" class="dataTables_length">
            <label class="d-inline-flex align-items-center">
              Show&nbsp;
              <b-form-select
                v-model="perPage"
                size="sm"
                :options="pageOptions"
                class="ml-2 mr-2"
              ></b-form-select
              >&nbsp;entries
            </label>
          </div>
        </div>
        <div class="col-12 col-sm-6">
          <div
            class="dataTables_paginate paging_simple_numbers"
            v-if="!this.$apollo.queries.wc_orders.loading"
          >
            <b-pagination
              v-model="currentPage"
              class="justify-content-center justify-content-sm-end"
              :total-rows="wc_orders.length"
              :per-page="perPage"
            ></b-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { GET_PILOT_ORDER_LIST } from "@/graphql/queries/queries";
import { DateTime } from "luxon";
export default {
  name: "pilotOrderListing",
  props: {
    pilotId: {
      types: String,
      default: null,
    },
  },
  data() {
    return {
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      pilot_id: this.pilotId,
      pilotOrdersTable: this.$apollo.queries.wc_orders,
      fields: [
        { key: "orderId", label: "Order", class: "order-id", sortable: false },
        { key: "flightType", label: "Flight", class: "flight-type" },
        {
          key: "startTime",
          label: "Date/Time",
          class: "date-time",
          sortable: false,
        },
        { key: "customerName", label: "Customer", class: "customer-name" },
        {
          key: "changeStatus",
          label: "Status",
          class: "ol-assignment-status",
        },
        { key: "totalPayment", label: "Payment", class: "payment-info" },
        { key: "actions", label: "", class: "action-links" },
      ],
    };
  },
  apollo: {
    wc_orders: {
      query: GET_PILOT_ORDER_LIST,
      variables() {
        return {
          pilot_id: this.pilot_id,
        };
      },
    },
  },
  methods: {
    formatDate(date) {
      // HACK: wp rest api timezone data is misleading and not adjusted for timezone.
      return DateTime.fromISO(date).toUTC().toLocaleString(DateTime.DATE_FULL);
    },
    formatTime(date) {
      // HACK: wp rest api timezone data is misleading and not adjusted for timezone.
      return DateTime.fromISO(date).toUTC().toFormat("h:mm a");
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep {
  .no-padding {
    padding: 0;
  }

  table.table {
    color: #6c757d;

    .order-id {
      font-weight: bold;
    }

    .flight-type {
      min-width: 235px;
    }

    .ops-zone,
    .pickup-loc,
    .order-channel {
      display: block;
    }

    .date-time {
      min-width: 150px;
    }

    .customer-name {
      font-weight: bold;
      min-width: 150px;
    }

    .customer-phone {
      display: block;
    }

    .pax-qty {
      font-weight: bold;
    }

    .order-payment {
      font-weight: bold;
      display: block;
    }

    .total-payment {
      font-weight: bold;
    }

    .total-payment.hide {
      display: none;
    }

    .razorpay-id {
      display: block;
    }

    .start-time {
      font-weight: bold;
      display: block;
    }

    .action-icon {
      display: inherit;
      padding: 0 5px;
      font-size: 1.2rem;
      color: #98a6ad;
      cursor: pointer;
    }

    .action-icon:hover {
      color: #6c757d;
    }
  }
}
</style>
