var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"table-responsive"},[_c('b-table',{staticClass:"orders-list",attrs:{"show-empty":"","striped":"","items":_vm.wc_orders,"fields":_vm.fields,"responsive":"sm","per-page":_vm.perPage,"current-page":_vm.currentPage},scopedSlots:_vm._u([{key:"cell(orderId)",fn:function(row){return [_vm._v(" #"+_vm._s(row.item.order_data.orderId)+" ")]}},{key:"cell(flightType)",fn:function(row){return [_vm._v(" "+_vm._s(row.item.order_data.flightType)+" ")]}},{key:"cell(startTime)",fn:function(row){return [_vm._v(" "+_vm._s(_vm.formatDate(row.item.order_data.startTime))+" "),_c('small',{staticClass:"start-time"},[_vm._v(_vm._s(_vm.formatTime(row.item.order_data.startTime)))])]}},{key:"cell(customerName)",fn:function(row){return [_vm._v(" "+_vm._s(row.item.order_data.customerName)+" "),_c('small',{staticClass:"customer-phone"},[_c('i',{staticClass:"uil uil-phone font-12 text-success mr-1"}),_vm._v(_vm._s(row.item.order_data.customerPhone))])]}},{key:"cell(changeStatus)",fn:function(row){return [_c('span',{staticClass:"badge mr-2",class:{
              'badge-success': row.item.order_data.bookingStatus === 'paid',
              'badge-info': row.item.order_data.bookingStatus === 'complete',
              'badge-danger':
                row.item.order_data.bookingStatus === 'cancelled',
              'badge-light': row.item.order_data.bookingStatus === 'unpaid',
              'badge-danger-lighten':
                row.item.order_data.bookingStatus === 'Payment Failed',
            }},[_vm._v(_vm._s(row.item.order_data.bookingStatus))])]}},{key:"cell(totalPayment)",fn:function(row){return [_c('span',{staticClass:"total-payment"},[_vm._v("₹"+_vm._s(parseInt(row.item.order_data.totalPayment)))])]}},{key:"cell(actions)",fn:function(){return [_c('a',{staticClass:"action-icon view",attrs:{"title":"View"}},[_c('i',{staticClass:"mdi mdi-eye"})])]},proxy:true}])})],1),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-sm-6 text-center text-sm-left mb-2 mb-sm-0"},[_c('div',{staticClass:"dataTables_length",attrs:{"id":"tickets-table_length"}},[_c('label',{staticClass:"d-inline-flex align-items-center"},[_vm._v(" Show "),_c('b-form-select',{staticClass:"ml-2 mr-2",attrs:{"size":"sm","options":_vm.pageOptions},model:{value:(_vm.perPage),callback:function ($$v) {_vm.perPage=$$v},expression:"perPage"}}),_vm._v(" entries ")],1)])]),_c('div',{staticClass:"col-12 col-sm-6"},[(!this.$apollo.queries.wc_orders.loading)?_c('div',{staticClass:"dataTables_paginate paging_simple_numbers"},[_c('b-pagination',{staticClass:"justify-content-center justify-content-sm-end",attrs:{"total-rows":_vm.wc_orders.length,"per-page":_vm.perPage},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})],1):_vm._e()])])])])}
var staticRenderFns = []

export { render, staticRenderFns }