<template>
  <div class="card">
    <div class="card-body">
      <h4 class="header-title mt-0 mb-3">Pilot Information</h4>
      <div class="text-left">
        <p class="text-muted">
          <strong>Zone :</strong>
          <span class="ml-2">{{ pilotData.operator.zone.name }}</span>
        </p>

        <p class="text-muted">
          <strong>Operator :</strong>
          <span class="ml-2">{{ pilotData.operator.company_name }}</span>
        </p>

        <p class="text-muted">
          <strong>Mobile :</strong>
          <span class="ml-2"
            ><a :href="`tel:${pilotData.user.phone_no}`">{{
              pilotData.user.phone_no
            }}</a>
            |
            <a :href="`tel:${pilotData.user.alternate_phone_no}`">{{
              pilotData.user.alternate_phone_no
            }}</a></span
          >
        </p>

        <p class="text-muted">
          <strong>Email :</strong>
          <span class="ml-2"
            ><a :href="`mailto:${pilotData.user.email}`">{{
              pilotData.user.email
            }}</a></span
          >
        </p>

        <p class="text-muted">
          <strong>License :</strong>
          <span class="ml-2">{{ pilotData.pilot_license.license_no }}</span>
        </p>

        <p class="text-muted">
          <strong>Rating :</strong>
          <span class="ml-2">Tandem</span>
        </p>

        <p class="text-muted">
          <strong>Inurance Vaildity :</strong>
          <span class="ml-2" v-if="pilotData.pilot_insurance"
            >{{ pilotData.pilot_insurance.issue_date }} /
            {{ pilotData.pilot_insurance.expiry_date }}</span
          >
        </p>
      </div>
    </div>
    <!-- end card-body -->
  </div>
  <!-- end card -->
</template>
<script>
export default {
  name: "pilotCard",
  props: {
    pilotData: {
      type: Object,
    },
  },
  data() {
    return {};
  },
};
</script>
